import logo from './logo.svg';
import coin_funchain_temp from "./coin_funchain_temp.png";
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <br/>
        <h2>Meme Coins Are Dead.<br/>We're Building What Comes Next.</h2>
        <div>
          <img src={coin_funchain_temp} className="App-logo" alt="logo" />
          
        </div>
        <p className='abs_text'>[ COMING SOON ]</p>
        <p>To be eligible for $FUN Airdrop:</p>
        <a
          className="App-link"
          href="https://x.com/FunchainCrypto"
          target="_blank"
          rel="noopener noreferrer"
        >
          1️⃣ Follow us on X
        </a>
        <br/>
        <a
          className="App-link"
          href="https://t.me/funchainofficial"
          target="_blank"
          rel="noopener noreferrer"
        >
          2️⃣ Join our Telegram
        </a>
      </header>
    </div>
  );
}

export default App;
